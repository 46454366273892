import { admin2 } from '@/api/instance'

// => 建立獎項
export const CreateReward = async ({
  shopId,
  lotteryId,
  image,
  name,
  availableStock,
  showAvailableStock,
  unlimitedStock,
  probability,
  award,
}) => {
  const res = await admin2({
    method: 'post',
    url: `/${shopId}/lotteryAward/${lotteryId}`,
    data: {
      image,
      name,
      availableStock,
      showAvailableStock,
      unlimitedStock,
      probability,
      award,
    },
  })
  return res
}

// => 取得獎項
export const GetReward = async ({ shopId, start, limit, lotteryId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/lotteryAward/${lotteryId}`,
    params: { start, limit },
  })
  return res
}

// => 取得獎項總數
export const GetRewardCount = async ({ shopId, lotteryId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/lotteryAward/${lotteryId}/count`,
  })
  return res
}

// => 查找獎項總數
export const FindReward = async ({ shopId, lotteryId, id }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/lotteryAward/${lotteryId}/${id}`,
  })
  return res
}

// => 查找獎項總數
export const UpdateReward = async ({
  shopId,
  lotteryId,
  id,
  image,
  name,
  availableStock,
  showAvailableStock,
  unlimitedStock,
  probability,
}) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/lotteryAward/${lotteryId}/${id}`,
    data: {
      image,
      name,
      availableStock,
      showAvailableStock,
      unlimitedStock,
      probability,
    },
  })
  return res
}

// => 查找獎項總數
export const DeleteReward = async ({ shopId, lotteryId, id }) => {
  const res = await admin2({
    method: 'delete',
    url: `/${shopId}/lotteryAward/${lotteryId}/${id}`,
  })
  return res
}
