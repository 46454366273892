<template>
  <div v-if="lottery" class="reward-manage">
    <PageTitle :title="lottery.name" btn="設定編輯" @btnClick="gameEditDialog = true" />

    <header>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/reward-content-setting' }">
          抽獎內容設定
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ lottery.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </header>

    <section class="flex justify-between items-center">
      <p>
        |活動期間 {{ dateFormat(lottery.startAt) }} ~
        {{ dateFormat(lottery.endAt) }}
      </p>
      <!-- <el-button type="primary" @click="gameEditDialog = true"
        >設定編輯</el-button
      > -->
    </section>

    <section class="flex items-center">
      <p class="whitespace-nowrap font-medium" style="margin-right: 30px">抽獎頁連結</p>
      <div class="flex items-center">
        <span
          class="url-text"
        >{{ lottery.liffLink }}</span>
        <el-button class="bg-transparent rounded-md" plain @click="copy(lottery.liffLink)">複製</el-button>
      </div>
    </section>

    <section class="text-right">
      <el-button
        type="primary"
        @click="openDialog('create')"
      >
        新增獎項
      </el-button>
    </section>

    <!-- Table -->
    <el-table :data="rewardList">
      <EmptyBlock slot="empty" />
      <el-table-column label="獎項名稱" prop="name" align="center" />
      <el-table-column label="獎項數量" prop="availableStock" align="center" />
      <el-table-column label="已送出數量" prop="usedStock" align="center" />
      <el-table-column label="中獎機率" prop="probability" align="center">
        <template slot-scope="scope">
          {{ rounded(scope.row.probability * 100) }}%
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        label="操作"
        fixed="right"
        width="110"
        align="center"
      >
        <template slot-scope="scope">
          <TableEditBtnGroup
            @edit="
              openDialog('update'),
              syncFormData(scope.row),
              (selectRow = scope.row)"
            @delete=";(deleteDialog = true), (selectRow = scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>

    <!-- Pagination -->
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="rewardCount"
      @pageChange="refresh"
    />

    <!-- Dialog -->
    <el-dialog
      v-if="gameEditDialog"
      title="抽獎編輯"
      :visible.sync="gameEditDialog"
      width="620px"
      :close-on-click-modal="false"
      @close="resetForm()"
    >
      <el-form
        ref="lotteryForm"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item label="頁面圖片">
          <img :src="imgUrl(lottery.Image)" alt="">
        </el-form-item>
        <el-form-item label="抽獎名稱">
          <span class="lottery-info">{{ lottery.name }}</span>
        </el-form-item>
        <el-form-item label="抽獎方式">
          <span class="lottery-info">{{ lottery.MemberGameMode ?lottery.MemberGameMode.name : '' }}</span>
        </el-form-item>
        <el-form-item label="抽獎條件設定">
          <p
            v-for="(req, index) in lotteryReqList()"
            :key="index"
            class="lottery-info"
            style="margin-bottom: 10px"
          >
            {{ req }}
          </p>
        </el-form-item>
        <el-form-item label="抽獎活動期間" prop="lotteryDate" :error="dateError? '活動結束時間不可晚於使用券到期時間。': ''">
          <el-date-picker
            v-model="lotteryDate"
            format="yyyy-MM-dd HH:mm"
            type="datetimerange"
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
            @change="(val) => (formData.lotteryDate = val)"
          />
        </el-form-item>

        <el-form-item label="活動說明">
          <quillEditor v-model="formData.description" :options="editorOption" />
        </el-form-item>
        <el-form-item label="注意事項">
          <quillEditor v-model="formData.notice" :options="editorOption" />
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button
          plain
          @click=";(gameEditDialog = false), resetForm()"
        >
          返回
        </el-button>
        <el-button type="primary" @click="updateMemberGame">儲存</el-button>
      </div>
    </el-dialog>

    <el-dialog
      v-if="showDialog"
      :title="dialogTitle"
      :visible.sync="showDialog"
      width="620px"
      :close-on-click-modal="false"
      @close="resetForm()"
    >
      <el-form
        ref="form"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item label="圖片" prop="img">
          <UploadButton
            :img="formData.img"
            :isAvatar="true"
            @change="loadImg"
          />
        </el-form-item>
        <el-form-item label="獎項名稱" prop="name">
          <p v-if="dialogType === 'update'">{{ formData.name }}</p>
          <el-input
            v-if="dialogType === 'create'"
            v-model="formData.name"
            placeholder="請輸入名稱"
          />
        </el-form-item>
        <el-form-item label="獎項類型" prop="type">
          <p v-if="dialogType === 'update'">
            {{ rewardTypes[formData.type].name }}
          </p>

          <el-select
            v-if="dialogType === 'create'"
            v-model="formData.type"
            placeholder="請選擇"
          >
            <el-option
              v-for="reward in rewardTypes"
              :key="reward.value"
              :label="reward.name"
              :value="reward.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="formData.type === 'point' || formData.type === 'cashback'"
          :label="formatAmountText(formData.type)"
          prop="amount"
        >
          <p v-if="dialogType === 'update'">{{ formData.amount }}</p>
          <el-input
            v-if="dialogType === 'create'"
            v-model="formData.amount"
            placeholder="請輸入數字"
            type="number"
            :min="0"
            :max="defaultRangeMax"
          />
        </el-form-item>
        <el-form-item
          v-if="formData.type === 'coupon'"
          label="票券綁定"
          prop="couponId"
        >
          <el-select
            v-model="formData.couponId"
            popper-class="select-popper"
            placeholder="選擇票券"
            @visible-change="showCouponSelectModal = true"
          >
            <el-option
              :label="findCouponName(formData.couponId)"
              :value="formData.couponId"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="機率" prop="probability">
          <el-input
            v-model="formData.probability"
            type="number"
            min="0"
            max="100"
            placeholder="獎項機率 0~100%"
          >
            <template slot="suffix">
              %
            </template>
          </el-input>
        </el-form-item>
        <el-form-item label="獎項數量" prop="availableStock">
          <el-input
            v-model="formData.availableStock"
            type="number"
            :min="-1"
            :max="defaultRangeMax"
            :placeholder="`請輸入數字0~${defaultRangeMax}，無限輸入-1`"
          />
        </el-form-item>
        <el-form-item prop="showAvailableStock">
          <el-switch
            v-model="formData.showAvailableStock"
            active-text="前台顯示數量"
            inactive-text="前台不顯示數量"
          />
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button
          plain
          @click=";(showDialog = false), resetForm()"
        >
          返回
        </el-button>
        <el-button type="primary" @click="dialogConfirm">
          {{
            dialogType === 'create' ? '新增' : '儲存'
          }}
        </el-button>
      </div>
    </el-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      @close="deleteDialog = false"
      @delete="deleteReward(), (deleteDialog = false)"
    />

    <ImageCropper
      v-if="uploadDialog"
      :image="uploadImg"
      @close="uploadDialog = false"
      @uploaded="getImage"
    />
    <CouponAdvanceSelect
      v-if="showCouponSelectModal"
      :data="couponList"
      :typeOptions="couponTypeConfig"
      disabledExp
      @confirm="onAddCoupon"
      @close="showCouponSelectModal = false"
    />
  </div>
</template>

<script>
// import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'
import { lotteryReqConfig, rewardTypesCreateConfig } from '@/config/lottery'
import { memberCardConfig } from '@/config/member'
import { rangeRules, noEmptyRules, defaultRangeMax } from '@/validation/index'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
// Mixin
import dialogMixin from '@/mixin/dialog'
import tableMixin from '@/mixin/table'
import imageMixin from '@/mixin/image'
// APIs
import { FindMemberGame, UpdateMemberGame } from '@/api/lottery/memberGame'
import {} from '@/api/memberTags'
import {
  GetReward,
  GetRewardCount,
  CreateReward,
  UpdateReward,
  DeleteReward,
} from '@/api/lottery/reward'
import { FindCoupon, GetCoupon, GetCouponCount } from '@/api/lottery/coupon'
import formUtils from '@/utils/form'
import copy from 'clipboard-copy'
import { imgSrc, getAllDataFromApi } from '@/utils/helper'
import { forEach, map, find, omit } from 'lodash'
import dayjs from 'dayjs'
import { computed } from 'vue'

// Editor
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import CouponAdvanceSelect from '@/components/Select/CouponAdvanceSelect.vue'
import { couponTypeConfig } from '@/config/couponExchange'
import { usePermissions } from '@/use/permissions'

export default {
  name: 'RewardManage',
  components: {
    // DeleteDialog,
    quillEditor,
    UploadButton,
    ImageCropper,
    CouponAdvanceSelect,
  },
  mixins: [dialogMixin, tableMixin, imageMixin],
  setup () {
    const { checkAction } = usePermissions()
    const useCoupon = computed(() => checkAction('admin.coupon.page'))
    const useExchangeCoupon = computed(() => checkAction('admin.couponExchange.page'))

    const useRebate = computed(() => checkAction('adminView.ecommerceProduct.useCashbackRate'))
    const useShopPoint = computed(() => checkAction('admin.shopPoint.page'))

    const rewardTypes = computed(() => {
      const omitList = []
      if (!useCoupon.value && !useExchangeCoupon.value) omitList.push('coupon')
      if (!useRebate.value) omitList.push('cashback')
      if (!useShopPoint.value) omitList.push('point')
      return omit(rewardTypesCreateConfig, omitList)
    })
    return { useCoupon, useExchangeCoupon, rewardTypes, useRebate, useShopPoint }
  },
  data: () => ({
    defaultRangeMax,
    lotteryReq: lotteryReqConfig,
    memberCard: memberCardConfig,
    dateError: false,
    // rewardTypes: rewardTypesCreateConfig,
    rewardList: [],
    rewardCount: 0,
    gameEditDialog: false,
    lottery: null,
    lotteryDate: [],
    formData: {
      lotteryDate: '',
      img: null,
      name: '',
      type: '',
      availableStock: '',
      probability: '',
      couponId: '',
      amount: '',
      coupon: '',
      showAvailableStock: false,
      description: '',
      notice: '',
    },
    formRules: {
      lotteryDate: [
        {
          required: true,
          message: '請輸入資料',
          trigger: 'blur',
        },
      ],
      // img: [noEmptyRules()],
      name: [noEmptyRules()],
      probability: [noEmptyRules(), rangeRules(0, 100)],
      availableStock: [noEmptyRules(), rangeRules(-1)],
      type: [noEmptyRules()],
      amount: [noEmptyRules(), rangeRules()],
      couponId: [noEmptyRules()],
    },
    editorOption: {
      modules: {
        toolbar: [
          // [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'bullet' }],
          ['link'],
        ],
      },
    },
    showCouponSelectModal: false,
    couponList: [],
  }),
  computed: {
    dialogTitle () {
      return this.changeDialogTitle({
        create: '新增獎項',
        update: '編輯獎項',
      })
    },
    couponTypeConfig () {
      const omitList = []
      if (!this.useCoupon) omitList.push('coupon')
      if (!this.useExchangeCoupon) omitList.push('exchange')
      return omit(couponTypeConfig, omitList)
    },

  },

  async mounted () {
    await this.refresh()
    if (this.useCoupon || this.useExchangeCoupon) await this.getAllCoupon()
  },
  methods: {
    rounded (value) {
      return Math.floor(value)
    },
    lotteryReqList () {
      const res = []
      forEach(this.lottery.requirements, (req) => {
        res.push(this.lotteryReq[req.type].name)

        if (req.type === 'memberCard') {
          const levelList = map(
            req.levels,
            (level) => this.memberCard[level].name,
          )
          res.push(levelList.join('、'))
        }
        if (req.type === 'totalUsed' || req.type === 'dayUsed') {
          res.push(req.times)
        }

        // TODO 會員標籤關聯? 難道要打API拿所有標籤比對?
        if (req.type === 'memberTag') {
          const tags = map(req.mTags, 'name')
          res.push(tags.join('、'))
        }
      })
      return res
    },
    imgUrl (src) {
      return imgSrc(150, src)
    },
    async syncFormData (res) {
      this.formData.img = res.Image
      this.formData.name = res.name
      this.formData.type = res.award?.type
      this.formData.amount = res.award?.amount
      this.formData.showAvailableStock = res.showAvailableStock
      this.formData.unlimitedStock = res.unlimitedStock
      this.formData.probability = res.probability * 100
      this.formData.availableStock = res.availableStock
      this.formData.couponId = res.award?.couponId
      this.formData.description = res.description
      this.formData.notice = res.notice
      if (res.award?.type === 'coupon') await this.findCoupon(res.award?.couponId)
    },
    async refresh () {
      await this.getReward()
      await this.getRewardCount()
      await this.findMemberGame()
    },

    copy (url) {
      copy(url)
      this.$message.success('已成功複製連結！')
    },

    resetForm () {
      this.dateError = false
      if (this.$refs.lotteryForm) formUtils.resetForm(this.$refs.lotteryForm)
      if (this.$refs.form) formUtils.resetForm(this.$refs.form)
      this.formData = {
        img: null,
        name: '',
        type: '',
        availableStock: '',
        probability: '',
        couponId: '',
        amount: '',
        coupon: '',
        showAvailableStock: false,
        description: '',
        notice: '',
      }
      const dateData = JSON.parse(JSON.stringify(this.lottery))
      const lotteryData = JSON.parse(JSON.stringify(this.lottery))
      this.lotteryDate = [dateData.startAt, dateData.endAt]
      this.formData.lotteryDate = this.lotteryDate
      this.formData.notice = lotteryData.notice
      this.formData.description = lotteryData.description
    },
    async dialogConfirm () {
      const handlerDict = {
        create: this.createReward,
        update: this.updateReward,
      }
      await handlerDict[this.dialogType]()
    },

    async findMemberGame () {
      const [res, err] = await FindMemberGame({
        shopId: this.shop,
        id: this.$route.query.id,
      })
      if (err) return this.$message.error(err)
      this.lottery = res
      this.lotteryDate = [res.startAt, res.endAt]
      this.formData.lotteryDate = this.lotteryDate
      this.formData.notice = this.lottery.notice
      this.formData.description = this.lottery.description
      // this.syncFormData(res)
    },

    //= > 創建獎項
    async createReward () {
      if (this.formData.type === 'coupon') {
        await this.findCoupon(this.formData.couponId)
        if (dayjs(this.formData.coupon.expAt).isBefore(dayjs(this.lottery.endAt))) {
          this.dateError = true
          this.$message.warning('使用券截止日期不可早於活動結束日')
          return
        }
      }

      if (!(await formUtils.checkForm(this.$refs.form))) return
      const [res, err] = await CreateReward({
        shopId: this.shop,
        lotteryId: this.$route.params.lotteryId,
        image: this.formData.img ? this.formData.img.id : undefined,
        name: this.formData.name,
        availableStock: this.formData.availableStock,
        showAvailableStock: this.formData.showAvailableStock,
        unlimitedStock: this.formData.availableStock === '-1',
        probability: this.formData.probability / 100,
        award: {
          type: this.formData.type,
          amount:
            this.formData.type === 'point' || this.formData.type === 'cashback' ? this.formData.amount : undefined,
          couponId:
            this.formData.type === 'coupon'
              ? this.formData.couponId
              : undefined,
        },
      })
      if (err) return this.$message.error(err)
      this.resetForm()
      this.showDialog = false
      await this.refresh()
    },

    //= > 更新獎項
    async updateReward () {
      if (!(await formUtils.checkForm(this.$refs.form))) return
      const [res, err] = await UpdateReward({
        shopId: this.shop,
        lotteryId: this.$route.params.lotteryId,
        id: this.selectRow.id,
        image: this.formData.img ? this.formData.img.id : undefined,
        name: this.formData.name,
        availableStock: this.formData.availableStock,
        showAvailableStock: this.formData.showAvailableStock,
        unlimitedStock: this.formData.availableStock === -1,
        probability: this.formData.probability / 100,
      })
      if (err) return this.$message.error(err)

      this.showDialog = false
      await this.refresh()
      await this.syncFormData(res)
    },
    //= > 取得獎項
    async getReward () {
      const [res, err] = await GetReward({
        shopId: this.shop,
        start: this.pageStartIndex,
        limit: this.tableOptions.pageLimit,
        lotteryId: this.$route.params.lotteryId,
      })
      if (err) return this.$message.error(err)

      this.rewardList = res
    },

    //= > 取得總數
    async getRewardCount () {
      const [res, err] = await GetRewardCount({
        shopId: this.shop,
        lotteryId: this.$route.params.lotteryId,
      })
      if (err) return this.$message.error(err)
      this.rewardCount = res.count
    },

    //= > 取得總數
    async deleteReward () {
      const [, err] = await DeleteReward({
        shopId: this.shop,
        lotteryId: this.$route.params.lotteryId,
        id: this.selectRow.id,
      })
      if (err) return this.$message.error(err)
      await this.refresh()
    },

    async findCoupon (couponId) {
      const [res, err] = await FindCoupon({
        shopId: this.shop,
        id: couponId,
      })

      if (err) return this.$message.error(err)
      this.formData.coupon = res
    },

    async updateMemberGame () {
      if (!(await formUtils.checkForm(this.$refs.lotteryForm))) return
      const [, err] = await UpdateMemberGame({
        shopId: this.shop,
        id: this.lottery.id,
        startAt: this.lotteryDate[0],
        endAt: this.lotteryDate[1],
        notice: this.formData.notice,
        description: this.formData.description,
      })
      if (err) {
        if (err.code === 'COUPON_EXPAT_MUST_LESS_THAN_ENDAT') {
          this.dateError = true
        }
        this.$message.error(err.msg || err)
        return
      }
      this.gameEditDialog = false
      await this.refresh()
    },

    async getCouponCount () {
      const [res, err] = await GetCouponCount({ shopId: this.shop, type: 'all' })
      if (err) throw err
      return res.count
    },
    async getAllCoupon () {
      let max
      try {
        max = await this.getCouponCount()
      } catch (error) {
        this.$message.error(error)
        return
      }
      const config = {
        shopId: this.shop,
        start: 0,
        limit: 100,
        type: 'all',
      }
      if (!this.useCoupon && this.useExchangeCoupon) config.type = 'exchange'
      else if (this.useCoupon && !this.useExchangeCoupon) config.type = 'coupon'
      const [res, err] = await getAllDataFromApi(
        max,
        GetCoupon,
        config,
        true,
      )
      if (err) return this.$message.error(err)
      this.couponList = res
    },
    onAddCoupon (coupon) {
      if (coupon.length > 0) {
        this.formData.couponId = coupon[0]
      }
    },
    findCouponName (id) {
      const coupon = find(this.couponList, { id })
      if (coupon) return coupon.name
      return ''
    },
    formatAmountText (type) {
      const config = {
        point: '點數',
        cashback: '回饋金',
      }
      return config[type]
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  line-height: unset;
}

.lottery-link {
  @apply flex items-center justify-between bg-white rounded-full py-[8px] px-[20px];
  @apply w-[472px];

  .copy-btn {
    @apply bg-secondary-100 text-white rounded-full cursor-pointer;
    @apply px-[30px] py-[1px] ml-[12px] w-[96px] whitespace-nowrap;
  }
}

.lottery-info {
  @apply text-normal font-normal leading-[24px] tracking-[1px];
}

.url-text {
  @apply text-gray-80 mr-[30px];
  @apply w-[250px] whitespace-nowrap overflow-hidden overflow-ellipsis;
}
</style>
